import PropTypes from 'prop-types';

const LoveSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.086 7.91683L19.0013 9.02918L17.9167 7.91686C14.5427 4.45681 9.07241 4.45681 5.69845 7.91685C2.41509 11.284 2.31421 16.7104 5.47002 20.2034L14.5367 30.2389C16.9454 32.905 21.0572 32.905 23.4659 30.2389L32.5326 20.2034C35.6884 16.7103 35.5875 11.2839 32.3042 7.91682C28.9302 4.45679 23.4599 4.45679 20.086 7.91683Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7305 10.2917C25.7305 9.63591 26.2621 9.10425 26.918 9.10425C29.3227 9.10425 31.2721 11.0537 31.2721 13.4584C31.2721 14.1143 30.7405 14.6459 30.0846 14.6459C29.4288 14.6459 28.8971 14.1143 28.8971 13.4584C28.8971 12.3654 28.011 11.4792 26.918 11.4792C26.2621 11.4792 25.7305 10.9476 25.7305 10.2917Z"
        fill={color}
      />
    </svg>
  );
};

LoveSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LoveSvg;
