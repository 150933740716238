import PropTypes from 'prop-types';

const PlusCircleSvg = ({ color, opacity, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PlusCircleSvg.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default PlusCircleSvg;
