import PropTypes from 'prop-types';

const PeopleSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.4"
        cx="11.8763"
        cy="13.4584"
        r="3.95833"
        fill={color}
      />
      <circle
        opacity="0.4"
        cx="26.1263"
        cy="13.4584"
        r="3.95833"
        fill={color}
      />
      <ellipse
        opacity="0.4"
        cx="19"
        cy="25.3335"
        rx="9.5"
        ry="4.75"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1191 28.4802C27.6023 27.6423 28.5026 26.5404 28.5026 25.3335C28.5026 23.4541 26.3196 21.8297 23.1523 21.0594C24.2725 20.7559 25.5556 20.5835 26.9192 20.5835C31.2915 20.5835 34.8359 22.3557 34.8359 24.5418C34.8359 26.728 31.2915 28.5002 26.9192 28.5002C26.6491 28.5002 26.3822 28.4934 26.1191 28.4802Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8514 21.0593C11.684 21.8295 9.50098 23.454 9.50098 25.3334C9.50098 26.5404 10.4013 27.6422 11.8845 28.4802C11.6214 28.4933 11.3546 28.5001 11.0846 28.5001C6.71238 28.5001 3.16797 26.7279 3.16797 24.5418C3.16797 22.3556 6.71238 20.5834 11.0846 20.5834C12.4482 20.5834 13.7312 20.7558 14.8514 21.0593Z"
        fill={color}
      />
      <circle cx="19" cy="12.6667" r="4.75" fill={color} />
    </svg>
  );
};

PeopleSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default PeopleSvg;
