import PropTypes from 'prop-types';

const LayersSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.6895 34.2376L4.75432 28.358C3.51679 27.7955 3.51679 26.0377 4.75432 25.4752L17.6895 19.5955C18.5221 19.2171 19.4777 19.2171 20.3103 19.5955L33.2454 25.4752C34.483 26.0377 34.483 27.7955 33.2454 28.358L20.3103 34.2376C19.4777 34.6161 18.5221 34.6161 17.6895 34.2376Z"
        fill={color}
      />
      <path
        d="M17.6895 26.321L4.75432 20.4414C3.51679 19.8789 3.51679 18.1211 4.75432 17.5585L17.6895 11.6789C18.5221 11.3005 19.4777 11.3005 20.3103 11.6789L33.2454 17.5585C34.483 18.1211 34.483 19.8789 33.2454 20.4414L20.3103 26.321C19.4777 26.6995 18.5221 26.6995 17.6895 26.321Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M17.6895 18.4043L4.75432 12.5247C3.51679 11.9622 3.51679 10.2044 4.75432 9.64186L17.6895 3.76223C18.5221 3.38378 19.4777 3.38378 20.3103 3.76223L33.2454 9.64186C34.483 10.2044 34.483 11.9622 33.2454 12.5247L20.3103 18.4043C19.4777 18.7828 18.5221 18.7828 17.6895 18.4043Z"
        fill={color}
      />
    </svg>
  );
};

LayersSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LayersSvg;
