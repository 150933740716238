import PropTypes from 'prop-types';

const UsersSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.4" cx="23.75" cy="12.6667" r="4.75" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5158 30.0015C26.4083 29.0886 26.9171 28.0366 26.9171 26.9166C26.9171 24.2229 23.9741 21.922 19.8252 21.0064C21.0216 20.7347 22.3507 20.5833 23.7504 20.5833C28.9971 20.5833 33.2504 22.71 33.2504 25.3333C33.2504 27.655 29.9189 29.5877 25.5158 30.0015Z"
        fill={color}
      />
      <ellipse
        opacity="0.4"
        cx="15.8333"
        cy="26.9166"
        rx="11.0833"
        ry="6.33333"
        fill={color}
      />
      <circle cx="15.8333" cy="11.0833" r="6.33333" fill={color} />
    </svg>
  );
};

UsersSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default UsersSvg;
