import PropTypes from 'prop-types';

import { ICONS_ALIASES } from '_constants/icons';

import ArrowRightSvg from './assets/ArrowRightSvg';
import RewardSvg from './assets/RewardSvg';
import SettingSvg from './assets/SettingSvg';
import SmileSvg from './assets/SmileSvg';
import UsersSvg from './assets/UsersSvg';
import CheckSvg from './assets/CheckSvg';
import PlusCircleSvg from './assets/PlusCircleSvg';
import MinusCircleSvg from './assets/MinusCircleSvg';
import CloseSquareSvg from './assets/CloseSquareSvg';
import TickSquareSvg from './assets/TickSquareSvg';
import LinkSvg from './assets/LinkSvg';
import LayersSvg from './assets/LayersSvg';
import LoveSvg from './assets/LoveSvg';
import PeopleSvg from './assets/PeopleSvg';
import TickSvg from './assets/TickSvg';

const icons = {
  [ICONS_ALIASES.arrowRight]: ArrowRightSvg,
  [ICONS_ALIASES.reward]: RewardSvg,
  [ICONS_ALIASES.setting]: SettingSvg,
  [ICONS_ALIASES.smile]: SmileSvg,
  [ICONS_ALIASES.users]: UsersSvg,
  [ICONS_ALIASES.check]: CheckSvg,
  [ICONS_ALIASES.plusCircle]: PlusCircleSvg,
  [ICONS_ALIASES.minusCircle]: MinusCircleSvg,
  [ICONS_ALIASES.closeSquare]: CloseSquareSvg,
  [ICONS_ALIASES.tickSquare]: TickSquareSvg,
  [ICONS_ALIASES.link]: LinkSvg,
  [ICONS_ALIASES.layers]: LayersSvg,
  [ICONS_ALIASES.love]: LoveSvg,
  [ICONS_ALIASES.people]: PeopleSvg,
  [ICONS_ALIASES.tick]: TickSvg,
};

const Icon = ({
  uniqueKey = '',
  alias,
  color,
  opacity,
  mixBlendMode,
  width,
  height,
}) => {
  const Icon = icons[alias];

  if (!Icon) {
    return null;
  }

  return (
    <Icon
      uniqueKey={uniqueKey}
      color={color}
      opacity={opacity}
      mixBlendMode={mixBlendMode}
      width={width}
      height={height}
    />
  );
};

Icon.propTypes = {
  uniqueKey: PropTypes.string,
  alias: PropTypes.oneOf([
    ICONS_ALIASES.arrowRight,
    ICONS_ALIASES.reward,
    ICONS_ALIASES.setting,
    ICONS_ALIASES.smile,
    ICONS_ALIASES.users,
    ICONS_ALIASES.check,
    ICONS_ALIASES.plusCircle,
    ICONS_ALIASES.minusCircle,
    ICONS_ALIASES.closeSquare,
    ICONS_ALIASES.tickSquare,
    ICONS_ALIASES.link,
    ICONS_ALIASES.layers,
    ICONS_ALIASES.love,
    ICONS_ALIASES.people,
    ICONS_ALIASES.tick,
  ]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  opacity: PropTypes.string,
  mixBlendMode: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Icon;
