import PropTypes from 'prop-types';

const SmileSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.4" cx="19.0003" cy="19" r="15.8333" fill={color} />
      <path
        d="M26.9167 15.8333C26.9167 16.7078 26.2078 17.4167 25.3333 17.4167C24.4589 17.4167 23.75 16.7078 23.75 15.8333C23.75 14.9589 24.4589 14.25 25.3333 14.25C26.2078 14.25 26.9167 14.9589 26.9167 15.8333Z"
        fill={color}
      />
      <path
        d="M14.2507 15.8333C14.2507 16.7078 13.5418 17.4167 12.6673 17.4167C11.7929 17.4167 11.084 16.7078 11.084 15.8333C11.084 14.9589 11.7929 14.25 12.6673 14.25C13.5418 14.25 14.2507 14.9589 14.2507 15.8333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.033 22.8573C14.5428 22.4271 13.7964 22.4729 13.3625 22.9611C12.9267 23.4513 12.9709 24.2018 13.4611 24.6376L14.25 23.75C13.4611 24.6376 13.4607 24.6372 13.4611 24.6376L13.4626 24.6389L13.4643 24.6404L13.4682 24.6438L13.478 24.6524L13.5058 24.6761C13.528 24.6948 13.5573 24.719 13.5937 24.7479C13.6665 24.8057 13.7679 24.8827 13.8966 24.9722C14.1536 25.151 14.5224 25.3817 14.9937 25.6102C15.9363 26.0672 17.3007 26.5208 19 26.5208C20.6993 26.5208 22.0638 26.0672 23.0064 25.6102C23.4776 25.3817 23.8464 25.151 24.1034 24.9722C24.2322 24.8827 24.3335 24.8057 24.4063 24.7479C24.4427 24.719 24.4721 24.6948 24.4942 24.6761L24.522 24.6524L24.5318 24.6438L24.5357 24.6404L24.5374 24.6389C24.5378 24.6386 24.5389 24.6376 23.75 23.75L24.5389 24.6376C25.0291 24.2018 25.0733 23.4513 24.6376 22.9611C24.2036 22.4729 23.4573 22.4271 22.967 22.8573L22.9656 22.8585C22.9602 22.863 22.9472 22.8738 22.9291 22.8882C22.8929 22.917 22.8318 22.9637 22.7472 23.0226C22.5774 23.1407 22.3154 23.3058 21.9702 23.4732C21.28 23.8078 20.2695 24.1458 19 24.1458C17.7306 24.1458 16.72 23.8078 16.0298 23.4732C15.6847 23.3058 15.4226 23.1407 15.2529 23.0226C15.1682 22.9637 15.1072 22.917 15.071 22.8882C15.0529 22.8738 15.0411 22.864 15.0357 22.8595C15.0345 22.8585 15.0336 22.8577 15.033 22.8573C15.033 22.8573 15.033 22.8572 15.033 22.8573ZM22.967 22.8573L22.9656 22.8585L22.9636 22.8602L22.9624 22.8613C22.9642 22.8597 22.9652 22.8588 22.967 22.8573Z"
        fill={color}
      />
    </svg>
  );
};

SmileSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SmileSvg;
