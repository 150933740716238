import PropTypes from 'prop-types';

const TickSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 12}`}
      height={`${height || 8}`}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.29515 8C4.05503 8 3.82691 7.91071 3.65883 7.75445L0.261131 4.59574C-0.0870435 4.27206 -0.0870435 3.73631 0.261131 3.41263C0.609305 3.08894 1.18559 3.08894 1.53377 3.41263L4.29515 5.97977L10.4662 0.242762C10.8144 -0.0809208 11.3907 -0.0809208 11.7389 0.242762C12.087 0.566446 12.087 1.1022 11.7389 1.42588L4.93147 7.75445C4.76338 7.91071 4.53527 8 4.29515 8Z"
        fill={color}
      />
    </svg>
  );
};

TickSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TickSvg;
