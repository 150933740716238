import PropTypes from 'prop-types';

const SettingSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.5114 4.75H17.4886C15.8192 4.75 14.4659 6.02599 14.4659 7.6C14.4659 9.40122 12.5387 10.5464 10.9567 9.6852L10.796 9.59773C9.35023 8.81073 7.50155 9.27777 6.66685 10.6409L5.15548 13.1091C4.32077 14.4722 4.81612 16.2152 6.26188 17.0022C7.84464 17.8638 7.84464 20.1362 6.26187 20.9978C4.81612 21.7848 4.32077 23.5278 5.15547 24.8909L6.66684 27.3591C7.50155 28.7222 9.35023 29.1893 10.796 28.4023L10.9567 28.3148C12.5387 27.4536 14.4659 28.5988 14.4659 30.4C14.4659 31.974 15.8192 33.25 17.4886 33.25H20.5114C22.1808 33.25 23.5341 31.974 23.5341 30.4C23.5341 28.5988 25.4613 27.4536 27.0434 28.3148L27.204 28.4022C28.6498 29.1893 30.4984 28.7222 31.3332 27.3591L32.8445 24.8909C33.6792 23.5278 33.1839 21.7847 31.7381 20.9977C30.1554 20.1361 30.1554 17.8639 31.7381 17.0023C33.1839 16.2153 33.6792 14.4722 32.8445 13.1091L31.3332 10.6409C30.4984 9.27779 28.6498 8.81075 27.204 9.59775L27.0433 9.68521C25.4613 10.5464 23.5341 9.40123 23.5341 7.6C23.5341 6.02599 22.1808 4.75 20.5114 4.75Z"
        fill={color}
      />
      <circle cx="19" cy="19" r="4.75" fill={color} />
    </svg>
  );
};

SettingSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SettingSvg;
