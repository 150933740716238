import PropTypes from 'prop-types';

const RewardSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 38}`}
      height={`${height || 38}`}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.083 25.5034V33.3563C11.083 34.0638 11.7975 34.5475 12.4544 34.2848L18.6283 31.8152C18.8667 31.7199 19.1327 31.7199 19.3711 31.8152L25.545 34.2848C26.2018 34.5475 26.9163 34.0638 26.9163 33.3563V25.5034C26.5707 25.6563 26.1948 25.7541 25.7999 25.7856L24.3166 25.904C23.5957 25.9615 22.9114 26.2449 22.3611 26.714L21.2285 27.6791C19.9442 28.7736 18.0552 28.7736 16.7708 27.6791L15.6383 26.714C15.0879 26.2449 14.4036 25.9615 13.6828 25.904L12.1995 25.7856C11.8045 25.7541 11.4287 25.6563 11.083 25.5034Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M16.7708 3.98757C18.0552 2.89306 19.9442 2.89306 21.2285 3.98757L22.3611 4.95273C22.9114 5.42175 23.5957 5.7052 24.3166 5.76272L25.7999 5.88109C27.482 6.01532 28.8177 7.35105 28.9519 9.03315L29.0703 10.5165C29.1278 11.2373 29.4113 11.9216 29.8803 12.472L30.8455 13.6045C31.94 14.8889 31.94 16.7779 30.8455 18.0622L29.8803 19.1948C29.4113 19.7451 29.1278 20.4294 29.0703 21.1502L28.9519 22.6336C28.8177 24.3157 27.482 25.6514 25.7999 25.7856L24.3166 25.904C23.5957 25.9615 22.9114 26.245 22.3611 26.714L21.2285 27.6791C19.9442 28.7736 18.0552 28.7736 16.7708 27.6791L15.6383 26.714C15.0879 26.245 14.4036 25.9615 13.6828 25.904L12.1995 25.7856C10.5174 25.6514 9.18164 24.3157 9.04741 22.6336L8.92904 21.1502C8.87152 20.4294 8.58807 19.7451 8.11905 19.1948L7.15389 18.0622C6.05938 16.7779 6.05938 14.8889 7.15389 13.6045L8.11905 12.472C8.58807 11.9216 8.87152 11.2373 8.92904 10.5165L9.04741 9.03315C9.18164 7.35105 10.5174 6.01532 12.1995 5.88109L13.6828 5.76272C14.4036 5.7052 15.0879 5.42175 15.6383 4.95273L16.7708 3.98757Z"
        fill={color}
      />
    </svg>
  );
};

RewardSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default RewardSvg;
