export const ICONS_ALIASES = {
  arrowRight: 'arrowRight',
  reward: 'reward',
  setting: 'setting',
  smile: 'smile',
  users: 'users',
  check: 'check',
  plusCircle: 'plusCircle',
  minusCircle: 'minusCircle',
  closeSquare: 'closeSquare',
  tickSquare: 'tickSquare',
  link: 'link',
  love: 'love',
  layers: 'layers',
  people: 'people',
  tick: 'tick',
};
